/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f5f5f5;
    line-height: 1.6;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

html, body {
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

    body::-webkit-scrollbar {
        width: 0;
        height: 0;
    }



video {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

/* Video background styling */
.video-bg {
    top: 0;
}

.video-bg-second {
    top: 100%;
}

.video-bg-third {
    top: 200%;
}

.pic-bg {
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Overlay */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.5);*/
}

/* Hero Section */
.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-height: 100%;
    text-align: center;
    color: #fff;
    z-index: 1; /* Ensure it's above the video */
}

@media (min-width: 768px) {

    .section-two {
        top: 100vh;
    }

    .section-three {
        top: 200vh;
    }

    .hero h1 {
        font-size: 5rem;
        margin-bottom: 20px;
        width: 50vw;
    }

    .hero p {
        font-size: 1.25rem;
        margin-bottom: 30px;
    }

    .hero button {
        position: absolute;
        padding: 15px 30px;
        font-size: 1rem;
        color: #fff; /* Change the text color */
        background-color: transparent;
        border: 2px solid;
        border-color: rgba(355, 355, 355, 0.7);
        border-radius: 5px;
        cursor: pointer;
        transition: border 0.3s;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        bottom: 50px;
    }

    .hero button p {
        padding: 0;
        font-size: 1rem;
        transition: border 0.3s;
        text-align: center;
        margin: auto;
    }

    .hero-title h1 {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 20vw;
        margin: 20px;
        color: white;
        opacity: 0.7;
    }
}

@media (max-width: 768px) {

    .hero {
        height: 89vh;
    }

    .hero h1 {
        font-size: 1.5rem;
        margin-bottom: 20px;
        width: 50vw;
    }

    .hero p {
        font-size: 1.00rem;
        margin-bottom: 30px;
    }

    .hero button {
        position: absolute;
        padding: 15px 30px;
        font-size: 0.8rem;
        text-align: center;
        color: #fff; /* Change the text color */
        background-color: transparent;
        border: 2px solid;
        border-color: rgba(355, 355, 355, 0.7);
        cursor: pointer;
        transition: border 0.3s;
        height: 50px;
        width: 150px;
        border-radius: 25px;
        bottom: 25px;
    }
}

.hero button:hover {
    border: 3px solid #fff;
}


@media (max-width: 768px) {

    .hero-title h1 {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 20vw;
        margin: 20px;
        color: white;
        opacity: 0.7;
        font-size: 1rem;
    }

    .section-two {
        top: 89vh;
    }

    .section-three {
        top: 178vh;
    }
 }

.section-two {
    position: absolute;
    width: 100%;
}

.section-three {
    position: absolute;
    width: 100%;
}

section {
    padding: 0;
    margin: 0;
}



    /* Features Section */
    .features {
        background-color: #fff;
        padding: 60px 20px;
        text-align: center;
    }

        .features h2 {
            font-size: 2.5rem;
            margin-bottom: 40px;
        }

        .features .feature-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
            border: 2px dashed black; /* Temporary Debugging Line */
        }

    @media (min-width: 768px) {
        .features .feature-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .feature-card {
        background-color: #f9f9f9;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

        .feature-card h3 {
            font-size: 1.5rem;
            margin-bottom: 15px;
        }

        .feature-card p {
            font-size: 1rem;
        }className="pic-bg"
                    src="pic_01.webp"


    /* Styling the cursor ring */
    .cursor-ring {
        position: absolute;
        width: 40px;
        height: 40px;
        border: 3px solid rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        pointer-events: none; /* Prevent interaction with other elements */
        transform: translate(-50%, -50%);
        transition: transform 0.2s ease, opacity 0.3s ease;
        opacity: 1; /* Invisible by default */
        z-index: 9999; /* Ensure it's on top of all other elements */
    }

    /* Make the ring visible when hovered */
    button:hover,
    a:hover {
        cursor: none; /* Hide the default cursor */
    }

        button:hover .cursor-ring,
        a:hover .cursor-ring {
            transform: scale(1.5); /* Slightly enlarge the ring on hover */
        }


/* MODAL */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

    .modal-content h2 {
        margin-bottom: 1rem;
    }

    .modal-content p {
        margin: 0.5rem 0;
    }

    .modal-content button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        cursor: pointer;
    }

        .modal-content button:hover {
            background-color: #0056b3;
        }
